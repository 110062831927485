const Schedule = () => {
  return (
    <section
      data-aos="fade-up"
      data-aos-delay="300"
      className="schedule-box fixed bottom-5 right-6 max-md:right-1 max-md:bottom-3 "
    >
      <a
        href="https://theauthenticapproach.clientsecure.me/"
        className="m-2 text-2xl"
      >
       
        <i className="transition fa-solid fa-calendar-days text-white p-4 bg-yellow-main hover:bg-yellow-main  rounded-full shadow-lg hover:-translate-y-1 hover:scale-105"></i>
      </a>
    </section>
  );
};

export default Schedule;
