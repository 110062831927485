

export const CallToME = () => {
  return (
    <section
    data-aos="fade-up"
    data-aos-delay="300"
    className="schedule-box fixed bottom-5 left-6  z-20   max-md:left-1 max-md:bottom-3">

    <a href="tel:+19452343273" className='m-2 text-2xl'> <i className="transition fa-solid fa-phone text-white  bg-yellow-main p-4   rounded-full  hover:-translate-y-1 hover:scale-105"></i></a>
</section>
  )
}
